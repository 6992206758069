import {
  openMsgModal,
  openSubModal,
  formatHHmmss,
  formatYYYYMMDD,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, LIMITED_MAX_MIN } from '@/common/const.js'
import PlanningFlagService from './PlanningFlagService'
import _ from 'lodash'

export function init(obj) {
  if (obj.selectedOrderIds.length > 0) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningFlagService.findDetailPackup(
      obj.selectedOrderIds[0],
      obj.selectedOrderIds
    )
      .then(
        (response) => {
          var getDatas = _.cloneDeep(response.data.dtoList)
          var datas = convertSnakeCace(getDatas)

          obj.tableItems = datas
          obj.packUpInfoList.tableItems = datas //save oridata

          // 配車オーダーの選択情報
          obj.displayData = response.data.dtoData
          obj.packUpInfoList.departureBeginDate =
            obj.displayData.departureBeginDate
          obj.packUpInfoList.departureBeginTime =
            obj.displayData.departureBeginTime
          obj.packUpInfoList.departureEndTime = obj.displayData.departureEndTime
          obj.packUpInfoList.departurePlaceName =
            obj.displayData.departurePlaceName
          obj.packUpInfoList.arrivalBeginDate = obj.displayData.arrivalBeginDate
          obj.packUpInfoList.arrivalBeginTime = obj.displayData.arrivalBeginTime
          obj.packUpInfoList.arrivalEndTime = obj.displayData.arrivalEndTime
          obj.packUpInfoList.arrivalPlaceName = obj.displayData.arrivalPlaceName
          obj.packUpInfoList.blockName = obj.displayData.blockName
          obj.packUpInfoList.planningGroupName =
            obj.displayData.planningGroupName
          //運送会社
          obj.packUpInfoList.assignCarrierName =
            obj.displayData.assignCarrierName
          //輸送手段
          obj.packUpInfoList.assignVehicleName =
            obj.displayData.assignVehicleName

          obj.packUpInfoList.planningOrderId = obj.displayData.planningOrderId

          var quantity = 0
          var weight = 0
          var volume = 0
          obj.tableItems.forEach((element) => {
            quantity = quantity + element.quantity
            weight = weight + element.weight
            volume = volume + element.volume
          })
          obj.packUpInfoList.totalQuantity = quantity
          obj.packUpInfoList.totalWeight = weight
          obj.packUpInfoList.totalVolume = volume

          obj.oriData = _.cloneDeep(obj.packUpInfoList)

          //検索結果の配車オーダに全て同じ荷纏めIDが設定されている場合は下記を行う
          //検索条件の指定車両（車両名～車型）に結果を表示する
          //荷纏め解除ボタン(F8ボタン)を活性にする
          var bKaijo = true
          var nHnmtmId = 0
          obj.tableItems.forEach((element) => {
            if (element.packup_flag == 0) {
              bKaijo = false
              return
            }
            nHnmtmId = obj.displayData.packupedId
            if (nHnmtmId != element.packuped_id) {
              bKaijo = false
              return
            }
          }) //荷纏めフラグ

          if (nHnmtmId > 0 && bKaijo == true) {
            obj.packupDisabled = false //「荷纏め解除」ボタン活性化
          } else {
            obj.packupDisabled = true
          }
          // 「解除待ち」情報がある場合、解除ボタン以外は非活性とする。
          // 遷移元では「解除待ち」が含まれている場合は
          // 選択している全件が「解除待ち」という仕様である。
          obj.registDisabled = false //「荷纏め解除」ボタン非活性化
          obj.rowDeleteDisabled = false //「荷纏め解除」ボタン非活性化
        },
        (e) => {
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message
          }
          // エラーモーダル表示
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export function clear(obj) {
  obj.packUpInfoList = _.cloneDeep(obj.oriData)
  obj.packUpInfoList.tableItems = _.cloneDeep(obj.oriData.tableItems)
  obj.tableItems = _.cloneDeep(obj.oriData.tableItems)
}

export function deleteRow(obj) {
  if (obj.selectedItem.length == 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG969E')
    )
    return
  }

  // 荷纏めの行削除処理
  //１行選択
  obj.selectedItem.forEach((element) => {
    const index = obj.tableItems.indexOf(element)
    obj.tableItems.splice(index, 1)
  })
}

/**
 * 「登録」ボタン
 * @param {*} obj
 */
export function goRegister(obj) {
  // 行が２行以上存在するかどうかチェックする。エラー時はメッセージを出力する。
  if (obj.tableItems.length < 1) {
    // ２件以上の場所を指定してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MB01S004_MG009E')
    )
    return
  }

  var camelPattern = convertCamelCace(obj.tableItems)

  let errorExist = false
  // 「荷纏め＝なし」
  if (obj.tableItems.length == 1) {
    // 纏められていないレコードの場合、2行以上レコードを選択してください。
    camelPattern.forEach((element) => {
      if (element.packupFlag == 0) {
        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage']('EB01S001_MG002E')
        )
        errorExist = true
        return
      }
    })
  }
  if (errorExist) return

  //データ上限値チェック
  var mNum = 0
  var mVol = 0
  var mWeight = 0
  camelPattern.forEach((element) => {
    mNum = mNum + element.quantity
    mVol = mVol + element.volume
    mWeight = mWeight + element.weight
    if (LIMITED_MAX_MIN.MAX < mNum) {
      openMsgModal(
        obj,
        MSG_TYPE.I,
        obj.$store.getters['init/getMessage']('EB03S001_MG001E', '数量', '登録')
      )
      errorExist = true
      return
    }
    if (LIMITED_MAX_MIN.MAX < mWeight) {
      openMsgModal(
        obj,
        MSG_TYPE.I,
        obj.$store.getters['init/getMessage']('EB03S001_MG001E', '質量', '登録')
      )
      errorExist = true
      return
    }
    if (LIMITED_MAX_MIN.MAX < mVol) {
      openMsgModal(
        obj,
        MSG_TYPE.I,
        obj.$store.getters['init/getMessage']('EB03S001_MG001E', '容量', '登録')
      )
      errorExist = true
      return
    }
  })
  if (errorExist) return

  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.register.label
  )
  openSubModal(obj, 'orderPackUp-register-modal')
}

export function register(obj) {
  obj.$bvModal.hide('orderPackUp-register-modal')

  var camelPattern = convertCamelCace(obj.tableItems)

  obj.displayData.departureBeginDate = obj.packUpInfoList.departureBeginDate
  obj.displayData.departureBeginTime = obj.packUpInfoList.departureBeginTime
  obj.displayData.departureEndTime = obj.packUpInfoList.departureEndTime
  obj.displayData.departurePlaceName = obj.packUpInfoList.departurePlaceName
  obj.displayData.arrivalBeginDate = obj.packUpInfoList.arrivalBeginDate
  obj.displayData.arrivalBeginTime = obj.packUpInfoList.arrivalBeginTime
  obj.displayData.arrivalEndTime = obj.packUpInfoList.arrivalEndTime
  obj.displayData.arrivalPlaceName = obj.packUpInfoList.arrivalPlaceName
  obj.displayData.blockName = obj.packUpInfoList.blockName
  obj.displayData.planningGroupName = obj.packUpInfoList.planningGroupName
  obj.displayData.planningOrderId = obj.packUpInfoList.planningOrderId

  obj.displayData.departureBeginTime = formatHHmmss(
    obj.displayData.departureBeginTime
  )
  obj.displayData.departureEndTime = formatHHmmss(
    obj.displayData.departureEndTime
  )
  obj.displayData.arrivalBeginTime = formatHHmmss(
    obj.displayData.arrivalBeginTime
  )
  obj.displayData.arrivalEndTime = formatHHmmss(obj.displayData.arrivalEndTime)
  obj.displayData.departureBeginDate = formatYYYYMMDD(
    obj,
    obj.displayData.departureBeginDate
  )
  obj.displayData.arrivalBeginDate = formatYYYYMMDD(
    obj,
    obj.displayData.arrivalBeginDate
  )

  let registerList = {
    entryKind: null,
    dtoData: obj.displayData,
    dtoList: camelPattern,
  }

  obj.$store.dispatch('init/setServiceLoading')
  PlanningFlagService.editPackup(
    obj.packUpInfoList.planningOrderId,
    registerList
  )
    .then(
      () => {
        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.resultControlInfo.register.label
          )
        )
        obj.$bvModal.hide('packup-modal')
        obj.$emit('after-close-set')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 「荷纏め解除」ボタン
 * @param {*} obj
 */
export function goPackupCancel(obj) {
  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.packup_cancel.label
  )
  openSubModal(obj, 'orderPackUp-cancel-modal')
}

/**
 * 「荷纏め解除」ボタン
 * @param {*} obj
 */
export function packupCancel(obj) {
  obj.$bvModal.hide('orderPackUp-cancel-modal')

  var camelPattern = convertCamelCace(obj.tableItems)

  obj.displayData.departureBeginDate = obj.packUpInfoList.departureBeginDate
  obj.displayData.departureBeginTime = obj.packUpInfoList.departureBeginTime
  obj.displayData.departureEndTime = obj.packUpInfoList.departureEndTime
  obj.displayData.departurePlaceName = obj.packUpInfoList.departurePlaceName
  obj.displayData.arrivalBeginDate = obj.packUpInfoList.arrivalBeginDate
  obj.displayData.arrivalBeginTime = obj.packUpInfoList.arrivalBeginTime
  obj.displayData.arrivalEndTime = obj.packUpInfoList.arrivalEndTime
  obj.displayData.arrivalPlaceName = obj.packUpInfoList.arrivalPlaceName
  obj.displayData.blockName = obj.packUpInfoList.blockName
  obj.displayData.planningGroupName = obj.packUpInfoList.planningGroupName
  obj.displayData.planningOrderId = obj.packUpInfoList.planningOrderId
  obj.displayData.departureBeginTime = formatHHmmss(
    obj.displayData.departureBeginTime
  )
  obj.displayData.departureEndTime = formatHHmmss(
    obj.displayData.departureEndTime
  )
  obj.displayData.arrivalBeginTime = formatHHmmss(
    obj.displayData.arrivalBeginTime
  )
  obj.displayData.arrivalEndTime = formatHHmmss(obj.displayData.arrivalEndTime)
  obj.displayData.departureBeginDate = formatYYYYMMDD(
    obj,
    obj.displayData.departureBeginDate
  )
  obj.displayData.arrivalBeginDate = formatYYYYMMDD(
    obj,
    obj.displayData.arrivalBeginDate
  )
  let packupCancelList = {
    entryKind: null,
    dtoData: obj.displayData,
    dtoList: camelPattern,
  }

  obj.$store.dispatch('init/setServiceLoading')
  PlanningFlagService.cancelPackup(
    obj.packUpInfoList.planningOrderId,
    packupCancelList
  )
    .then(
      () => {
        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.resultControlInfo.packup_cancel.label
          )
        )
        obj.$bvModal.hide('packup-modal')
        obj.$emit('after-close-set')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
