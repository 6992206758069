<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div
          v-if="buttonItems"
          class="ml-1 mr-1 mb-3 row justify-content-between"
        >
          <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
            <BaseButton
              v-for="(item, index) in group"
              :key="index"
              class="mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
        </div>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="packUpInfoList"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
        <br />
        <section data-class="work-list-table">
          <!-- テーブル -->
          <BaseTable
            selectMode="single"
            :fields="fields"
            :items="tableItems"
            @row-selected="onRowSelected"
          />
        </section>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- クリアボタン -->
        <BaseButton
          variant="primary"
          :columnInfo="resultControlInfo.clear"
          @click="clear(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 登録モーダル -->
    <ConfirmModal
      id="orderPackUp-register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 解除モーダル -->
    <ConfirmModal
      id="orderPackUp-cancel-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="packupCancel(obj)"
    />
    <!-- 情報モーダル -->
    <!-- <InfoModal
      id="orderPackup-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back"
    /> -->
  </div>
</template>
<script>
import packUpInfo from './OrderPackUpInfo'
import {
  init,
  deleteRow, //行削除
  packupCancel, //荷纏め解除
  goPackupCancel, //荷纏め解除
  clear,
  //back,
  goRegister,
  register,
} from './OrderPackUpModal.js'
export default {
  data() {
    return {
      tableItems: [], //CurrentList
      selectedItem: [],
      packUpInfoList: new packUpInfo(), //SearchList
      displayData: {},
      obj: this,
      registerMessage: null,
      packupDisabled: true,
      rowDeleteDisabled: true,
      registDisabled: true,
      oriData: new packUpInfo(),
    }
  },

  props: {
    selectedOrderIds: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    selectedOrderIds() {
      init(this)
    },
  },

  computed: {
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.ORDER_PACKUP_MODAL
      )('result_ctrl_info')
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.ORDER_PACKUP_MODAL
      )('detail_info')
      info.departure_place_name.visibility = this.VISIBILITY.DISABLE
      info.arrival_place_name.visibility = this.VISIBILITY.DISABLE
      info.block_name.visibility = this.VISIBILITY.DISABLE
      info.planning_group_name.visibility = this.VISIBILITY.DISABLE
      info.assign_carrier_name.visibility = this.VISIBILITY.DISABLE
      info.assign_vehicle_name.visibility = this.VISIBILITY.DISABLE
      info.total_quantity.visibility = this.VISIBILITY.DISABLE
      info.total_weight.visibility = this.VISIBILITY.DISABLE
      info.total_volume.visibility = this.VISIBILITY.DISABLE
      return info
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.ORDER_PACKUP_MODAL
      )(this.DOMAIN_NAME.FIELDS)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */ infoMessage() {
      return this.$store.getters['init/getMessage']('YZ00MG995I', '登録')
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 行削除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.row_delete,
            disabled: this.rowDeleteDisabledCheck(),
            onClickCallback: () => {
              deleteRow(this)
            },
          },
          // 荷纏め解除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.packup_cancel,
            disabled: this.packupDisabled,
            onClickCallback: () => {
              goPackupCancel(this)
            },
          },
          // 登録ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.register,
            disabled: this.registDisabled,
            onClickCallback: () => {
              goRegister(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 場所タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 荷積予定日時
              type: 'date',
              id: 'departureBeginDate',
              columnInfo: this.detailInfo.departure_begin_date,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 荷積予定日時
              type: 'timeFromTo',
              fromId: 'departureBeginTime',
              toId: 'departureEndTime',
              fromColumnInfo: this.detailInfo.departure_begin_time,
              toColumnInfo: this.detailInfo.departure_end_time,
              fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 荷積場所
              type: 'text',
              id: 'departurePlaceName',
              columnInfo: this.detailInfo.departure_place_name,
            },
            {
              // 荷卸予定日時
              type: 'date',
              id: 'arrivalBeginDate',
              columnInfo: this.detailInfo.arrival_begin_date,
              rules: [this.INPUT_TYPE_CHECK.DATE],
            },
            {
              // 荷卸予定日時
              type: 'timeFromTo',
              fromId: 'arrivalBeginTime',
              toId: 'arrivalEndTime',
              fromColumnInfo: this.detailInfo.arrival_begin_time,
              toColumnInfo: this.detailInfo.arrival_end_time,
              fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 荷卸場所
              type: 'text',
              id: 'arrivalPlaceName',
              columnInfo: this.detailInfo.arrival_place_name,
            },
            {
              // ブロック名
              type: 'text',
              id: 'blockName',
              columnInfo: this.detailInfo.block_name,
            },
            {
              // 配車計画グループ
              type: 'text',
              id: 'planningGroupName',
              columnInfo: this.detailInfo.planning_group_name,
            },
            {
              // 運送会社名
              type: 'text',
              id: 'assignCarrierName',
              columnInfo: this.detailInfo.assign_carrier_name,
            },
            {
              // 輸送手段名
              type: 'text',
              id: 'assignVehicleName',
              columnInfo: this.detailInfo.assign_vehicle_name,
            },
            {
              // 総数量
              type: 'text',
              id: 'totalQuantity',
              columnInfo: this.detailInfo.total_quantity,
            },
            {
              // 総質量
              type: 'text',
              id: 'totalWeight',
              columnInfo: this.detailInfo.total_weight,
            },
            {
              // 総容量
              type: 'text',
              id: 'totalVolume',
              columnInfo: this.detailInfo.total_volume,
            },
          ],
        },
      ]
    },
  },

  methods: {
    clear,
    register,
    packupCancel,
    //back,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    /**
     * 行削除ボタン
     */
    rowDeleteDisabledCheck() {
      var result = this.rowDeleteDisabled
      if (!result) {
        result = !this.oneSelected()
      }
      return result
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },
  },
}
</script>
